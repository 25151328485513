.UE-container {
  position: absolute;
  width: 283px; 
  height:506px;
  left:0px;
  top:0px;
}

.UE-img {
  position: absolute;
  width: 283px;
  height: 196px;
  left: 0px;
  top: 0px;
}

.UE-condensed {
  position: absolute;
  width: 283px;
  height: 196px;
  left: 0px;
  top: 196px;

  background: #FFFFFF;
}

.UE-expanded {
  position: absolute;
  width: 283px;
  height: 310px; 
  left: 0px;
  top: 196px;

  background: #FFFFFF;
}

.UE-title {
  position: absolute;
  width: 100%;
  height: 32px;
  left: 20px;
  top: 10px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  
  
  color: #000000;
}

.UE-content {
  position: absolute;
  width: 242px;
  height: 96px;
  left: 20px;
  top: 105px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  
  color: #000000;
}

.UE-date-time {
  position: absolute;
  width: 215px;
  height: 48px;
  left: 55px;
  top: 50px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: #000000;
}

.UE-clock {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 20px;
  top: 60px; 
}

.UE-plus {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor:pointer;
}

.UE-minus {
  position: absolute;
  right:15px;
  bottom:15px;
  cursor:pointer;
}

.join-btn {
  position: absolute;
  width: 219px;
  height: 44px;
  left: 30px;
  bottom:45px;

  background: #241474;
  border-color: transparent;
  cursor: pointer;
}

.join-btn-txt {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;

  color: #FFFFFF;
}