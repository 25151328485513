.pub-card-container {
  position: relative;
  width: 311px;
  height: 434px;
  left: 0px;
  top: 0px;
}

.pub-card-img {
  position: absolute;
  width: 311px;
  height: 258px;
  left: 0px;
  top: 0px;
}

.pub-card-condensed {
  position: absolute;
  width: 311px;
  height:60px;
  left:0px;
  top:258px;
}

.pub-card-expanded {
  position: absolute;
  width: 311px;
  height: 170px;
  left:0px;
  top:258px;
}

.pub-card-title {
  position: absolute;
  width: 133px;
  height: 32px;
  left: 40px;
  top: 10px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  
  
  color: #FFFFFF;
}

.pub-expand {
  position: absolute;
  left: 5px;
  top: 15px;
  cursor:pointer;
}

.pub-condense {
  position: absolute;
  left: 5px;
  top: 10px;
  cursor:pointer;
}

.pub-card-txt {
  position: absolute;
  width: 307px;
  height: 110px;
  left: 5px;
  top: 50px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  
  color: #FFFFFF;
}