.events-background {
  position: relative;
  width: 100%;
  height: 100%;

  background: linear-gradient(116.97deg, #181472 0%, #FF00B8 105.35%);
  display:flex;
  flex-direction: column;
}

.events-black-background {
  position: absolute;
  width: 100%;
  height: 1010px;
  left: 0px;
  top: 811px;

  background: #000000;
}

.events-header-element{
  height:auto;
  width:100%;
}

.events-header-flex-container {
  min-height:300px;
  height:auto;
  width:100%;
  display:flex;
  flex-direction:row;
}

.events-header-container {
  height:auto;
  width:63.5%;
  display:flex;
  flex-direction:column;
}

.events-page-title {
  position: relative;
  width: 245px;
  height: auto;
  left: 15%;
  top: 68px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 95px;

  color: #FFFFFF;
}

.events-page-subtitle {
  position: relative;
  width: 80%;
  height: auto;
  left: 15%;
  margin-top: 70px;
  margin-bottom: 50px;

  font-family: Red Hat Display;
  font-style: italic;
  font-weight: 500;
  font-size: 40px;
  line-height: 53px;

  color: #FFFFFF;
}

.eye-w-play-btn {
  position: relative;
  width: 380px;
  right: 0px;
  top: 60px;
  padding-bottom: 90px;
}

.events-upcoming-flex-container {
  height: auto;
  width: 100%;
  display:flex;
  flex-direction: column;
}

.events-upcoming-container {
  height:auto;
  width:100%;
  display:flex;
  flex-direction: row;
}

.events-subheader {
  position: relative;
  width: 195px;
  height: 106px;
  left: 50px;
  top: 0px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  text-align: right;

  color: #FFFFFF;

}

.events-style-line {
  position: relative;
  width: 247px;
  height: 0px;
  left: 0px;
  top: 0px;

  border: 5px solid #FFFFFF;
  background-color: #FFFFFF;
}


.upcoming-events-container {
  position: relative;
  width: 80%;
  height: 506px;
  left: 0%;
  top: 35px;
  overflow: hidden;
}

.events-upcoming-arrow-container {
  width: 20%;
  height: auto;
  display: flex;
  flex-direction:column;
  align-items: center;
}

.event-right-arrow {
  position: relative;
  width: 66px;
  height: 66px;
  top: 25%;  
  cursor:pointer;
}

.event-left-arrow {
  position: relative;
  width: 66px;
  height: 66px;
  top: 45%;
  transform: scaleX(-1);
  cursor:pointer;
}
@media screen and (max-width: 750px) {
  .event-upcoming-arrow-container {
    width: 15%;
  }
  .upcoming-events-container{
    width: 85%;
  }
}

.event-email-flex-container {
  height: auto;
  padding-bottom: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.event-email-title {
  position: relative;
  width: 60%;
  height: auto;
  top: 100px;
  align-self: center;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  /* identical to box height */

  color: #FFFFFF;
}

.event-email-subtitle {
  position: relative;
  width: 63%;
  height: auto;
  top: 100px;
  align-self: center;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height */
  
  color: #FFFFFF;
}

.event-email-field {
  position: relative;
  width: 52.5%;
  height: 66px;
  top: 180px;
  left:23%;
}

.event-email-btn-container {
  position: relative;
  width: 54.5%;
  height: 66px;
  top: 180px;
  left:23%;
}

@media screen and (max-width: 800px) {
  .event-email-field {
    left: 10%;
  }
  .event-email-btn-container {
    width: 95%;
    left: 0%;
  }
}
@media screen and (max-width: 800px) {
  .event-email-field {
    left: auto;
    align-self: center;
    width: 70%;
  }
  .event-email-btn-container {
    left: auto;
    align-self:center;
    width: 70%;
    top: 250px;
  }
  .email-field-btn {
    right: 0%;
    /* align-self: center; */
  }
  .event-spacer {
    position: relative;
    height:100px;
    width: 100%;
  }
}

.past-events-flex-container {
  width: 100%;
  height: auto;
  display:flex;
  flex-direction: column;
}

.past-events-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 18px;
  padding-bottom: 50px;
}

.past-events-swiper-container {
  position: relative;
  width: 80%;
  height: 370px;
  overflow: hidden;
}


.event-host-flex-container {
  height:auto;
  width:100%;
  display: flex;
  flex-direction: column;
}

.event-host-title {
  position: relative;
  width: 55%;
  height: auto;
  left: 40%;
  margin-top: 120px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  /* identical to box height */

  color: #FFFFFF;
}

.event-host-subtitle {
  position: relative;
  width: 54%;
  height: auto;
  left: 40%;
  top: 0px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #FFFFFF;
}


.events-globe {
  position: absolute;
  width: 890.93px;
  height: 871px;
  left: -170px;
  bottom: 10px;
}

@media screen and (max-width: 950px) {
  .events-globe {
    bottom:340px;
  }
}

.event-host-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 64px;

  position: relative;
  width: 315px;
  height: 76px;
  left: 55%;
  margin-top: 100px;
  margin-bottom: 170px;

  /* Brand Gradient - 1 */

  background: linear-gradient(104.61deg, #FF00B8 2.65%, #FF655B 51.83%, #FFC700 100%);
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.event-host-btn-txt {
  position: static;
  width: 159px;
  height: 32px;
  left: 92px;
  top: 22px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;


  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.event-host-btn-plus {
  position: static;
  width: 20px;
  height: 20px;
  left: 64px;
  top: 28px;


  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.event-footer-flex-container {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 750px) {
  .events-header-flex-container {
    flex-direction: column;
    gap: 40px;
  }
  .eye-w-play-btn {
    align-self: center;
  }

  .events-page-subtitle {
    margin-bottom: 0px;
}
}