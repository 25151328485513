.modal {
  background-color: white;
  position: absolute;
  min-width: 30%;
  min-height: 80px;
  max-width: 50%;
  width: auto;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #151372;
}

.modal-txt {
  font-family: Red Hat Display;
  font-size: 30px;
  color:#151372;
  ;
  text-align: center;
}

.close {
  color: #151372;
  float: right;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
}