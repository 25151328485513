.join-background {
  position: absolute;
  width: 100%;
  height: auto;

  background: linear-gradient(116.97deg, #181472 0%, #FF00B8 105.35%);
  display: flex;
  flex-direction: column;
}

.join-black-background1 {
  position: absolute;
  width: 100%;
  height: 794px;
  left: 0px;
  top: 881px;

  background: #000000;
}

.join-black-background2 {
  position: absolute;
  width: 100%;
  height: 1121px;
  left: 0px;
  top: 2709px;

  background: #000000;
}

.join-header-element{
  height:auto;
  width:100%;
}

.join-header-flex-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.join-page-title {
  position: relative;
  width: 20%;
  height: auto;
  left: 11%;
  margin-top: 68px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 95px;
  
  color: #FFFFFF;
}

.join-page-subtitle {
  position: relative;
  width: 55%;
  height: auto;
  left: 11%;
  margin-bottom: 68px;

  font-family: Red Hat Display;
  font-style: italic;
  font-weight: 500;
  font-size: 50px;
  line-height: 66px;

  color: #FFFFFF;
}

.join-be-part-flex-container {
  height:auto;
  width:100%;
}

.join-subheader {
  position: relative;
  width: 160px;
  height: 106px;
  padding-left: 87px;
  top: 0px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  text-align: right;
  /* identical to box height */
  
  color: #FFFFFF;
}

.join-style-line {
  position: relative;
  width: 247px;
  height: 0px;
  left: 0px;
  top: 0px;
  
  border: 5px solid #FFFFFF;
  background-color: #FFFFFF;
}

.join-part-txt {
  position: relative;
  width: calc(90% - 247px);
  left: 247px;
  height: auto;
  padding-top: 20px;
  padding-bottom: 130px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #FFFFFF;
}

.join-positions-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.join-positions-style-line {
  position: relative;
  width: calc(80% - 6px);
  height: 0px;
  left: 20%;
  top: 109px;

  border: 3px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
}

.join-positions-style-headers {
  height:auto;
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 36px;
}

.join-positions-container {
  position:relative;
  width:80%;
  height: 455px;
  left: 20%;
  margin-top: 100px;
  margin-bottom: 300px;
  overflow: hidden;
}

.join-member-flex-container {
  height:430px;
  width:100%;
}

.join-fellowship-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.join-pgm-txt {
  position: relative;
  width: calc(90% - 247px);
  left: 247px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 100px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #FFFFFF;

  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

@media screen and (max-width: 750px) {
  .join-pgm-txt, .join-part-txt {
    width: 70%;
    left: 20%;
  }
}

.join-what-we-do-flex-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.join-what-we-do-title {
  position: relative;
  width: 90%;
  height: auto;
  left: 5%;
  margin-top: 100px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  text-align: center;

  color: #FFFFFF;
}

.join-circle-flex-container {
  width: 100%;
  position: relative;
  margin-top:100px;
  margin-bottom: 320px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 7%;
}

@media screen and (max-width: 890px) {
  .join-circle-flex-container{
    margin-bottom: 100px;
  }
}

.join-email-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.join-email-header {
  position: relative;
  width: 60%;
  height: auto;
  left: 30%;
  margin-top: 200px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  /* identical to box height */
  
  color: #FFFFFF;
}

.join-email-subheader {
  position: relative;
  width: 38%;
  height: auto;
  left: 45%;
  top: 0px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height */
  
  
  color: #FFFFFF;
}

.join-email-field {
  position: relative;
  width: 52.5%;
  height: 66px;
  top: 0px;
  left:35%;
  margin-top:100px;
}

.join-btn-container {
  position: relative;
  width: 54.5%;
  height: 66px;
  top: 0px;
  left:35%;
}

.join-spacer {
  position: relative;
  height:100px;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .join-email-field {
    left: auto;
    align-self: center;
    width: 70%;
  }
  .join-btn-container {
    left: auto;
    align-self:center;
    width: 70%;
    top: 70px;
  }
  .email-field-btn {
    right: 0%;
    /* align-self: center; */
  }
  .join-spacer {
    height:300px;
  }
}

.join-footer-container {
  width: 100%;
  height: auto;
}