.publications-background {
  position: relative;
  width: 100%;
  height: auto;

  background: linear-gradient(116.97deg, #181472 0%, #FF00B8 105.35%);
  display: flex;
  flex-direction: column;
}

.publications-black-background1 {
  position: absolute;
  width: 100%;
  height: 1073px;
  left: 0px;
  top: 1034px;

  background: #000000;
}

.publications-black-background2 {
  position: absolute;
  width: 100%;
  height: 1375px;
  left: 0px;
  top: 2783px;

  background: #000000;
}

.pub-header-element{
  height:auto;
  width:100%;
}

.pub-header-flex-container{
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.publications-page-title {
  position: relative;
  width: 28%;
  height: auto;
  left: 11%;
  margin-top: 68px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 95px;
  
  color: #FFFFFF;
}

.publications-page-subtitle {
  position: relative;
  width: 63%;
  height: auto;
  left: 11%;
  top: 0px;
  margin-bottom: 41px;
  
  font-family: Red Hat Display;
  font-style: italic;
  font-weight: 500;
  font-size: 40px;
  line-height: 53px;
  
  color: #FFFFFF;
}

.latest-pub-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.publications-subheader {
  position: relative;
  width: 233px;
  height: 106px;
  left: 13px;
  top: 0px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  text-align: right;
  
  color: #FFFFFF;
}

.publications-style-line {
  position: relative;
  width: 247px;
  height: 0px;
  left: 0px;
  top: 0px;
  
  border: 5px solid #FFFFFF;
  background-color: #FFFFFF;
}

.featured-article {
  position: relative;
  width: 80%;
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.featured-article-img{
  position: relative;
  width: 452px;
  height: 375px;
  left: 0px;
  top: 0px;
}

.featured-article-info{
  position: relative;
  width: 60%;
  height: auto;
  margin-left: 2%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}

.featured-article-txt {
  position: relative;
  width: 100%;
  height: 24px;
  left: 0px;
  top: 0px;

  font-family: Red Hat Display;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  color: #FFFFFF;
}

.featured-article-title {
  position: relative;
  width: 100%;
  height: auto;
  left: 0px;
  margin-top: 20px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 73px;
  /* identical to box height */


  color: #FFFFFF;
}

.featured-article-dateauthor {
  position: relative;
  width: 224px;
  height: auto;
  left: 0px;
  margin-top: 0px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #FFFFFF;
}

.featured-article-desc {
  position: relative;
  width: 100%;
  height: auto;
  left: 0px;
  margin-top: 30px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  color: #FFFFFF;
}

@media screen and (max-width: 1000px) {
  .featured-article{
    flex-direction: column;
  }
}

.pubs-container {
  position:relative;
  min-height: 434px;
  height:auto;
  margin-top: 40px;
  margin-bottom:81px;
  padding-left: 70px;
  padding-right: 70px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3%;
}

.show-more-btn {
  position: relative;
  width: 475px;
  height: 68px;
  align-self: center;
  top: 0px;
  margin-bottom: 96px;

  border: 4px solid #FFFFFF;
  box-sizing: border-box;
  background-color: #000000;
  cursor: pointer;
}

.show-more-btn-txt {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height */

  color: #FFFFFF;
}

.pub-soon-flex-container {
  height: auto;
  width:100%;
  display: flex;
  flex-direction: column;
}

.coming-soon-flex-container {
  position: relative;
  height: auto; 
  width:100%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.coming-soon-arrows {
  height: auto;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pub-right-arrow {
  position: relative;
  width: 66px;
  height: 66px;
  top: 25%;  
  cursor:pointer;
}

.pub-left-arrow {
  position: relative;
  width: 66px;
  height: 66px;
  top: 40%;
  transform: scaleX(-1);
  cursor:pointer;
}

.coming-soon-swiper-container{
  position: relative;
  width: 80%;
  height:478px;
  left: 0px;
  top: 0px;
  overflow: hidden;
  margin-bottom: 130px;
}

@media screen and (max-width: 750px) {
  .coming-soon-arrows {
    width: 15%;
  }
  .coming-soon-swiper-container{
    width: 85%;
  }
}

.pub-food-flex-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.food-for-think-container {
  position: relative;
  width: 80%;
  height: 400px;
  left: 20%;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.pub-news-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.newsletters-container {
  position:relative;
  min-height: 434px;
  height:auto;
  margin-top: 40px;
  margin-bottom:81px;
  padding-left: 70px;
  padding-right: 70px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3%;
}

.pub-research-flex-container {
  width:100%;
  height:auto;
  display: flex;
  flex-direction: column;
}

.research-flex-box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.research-articles-container {
  position: relative;
  height:478px;
  width: 80%;
  margin-top:20px;
  left:0px;
  overflow: hidden;
}

.publications-globe {
  position: absolute;
  width: 890.93px;
  height: 871px;
  left: -180px;
  bottom: 10px;
}

@media screen and (max-width: 950px) {
  .publications-globe {
    bottom:340px;
  }
}

.pub-join-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.pub-join-header {
  position: relative;
  width: 39%;
  height: auto;
  left: 45%;
  margin-top: 190px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  /* identical to box height */


  color: #FFFFFF;
}

.pub-join-subheader {
  position: relative;
  width: 40%;
  height: auto;
  left: 45%;
  top: 0px;
  margin-bottom: 53px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height */


  color: #FFFFFF;
}

.pub-email-field {
  position: relative;
  width: 52.5%;
  height: 66px;
  top:0px;
  left:35%;
  margin-top:50px;
}

.pub-btn-container {
  position: relative;
  width: 54.5%;
  height: 66px;
  top: 0px;
  left:35%;
  margin-bottom:100px;
}

@media screen and (max-width: 800px) {
  .pub-email-field {
    left: auto;
    align-self: center;
    width: 70%;
  }
  .pub-btn-container {
    left: auto;
    align-self:center;
    width: 70%;
    top: 70px;
  }
  .email-field-btn {
    right: 0%;
    /* align-self: center; */
  }
  .pub-spacer {
    position: relative;
    height:200px;
    width: 100%;
  }
}

.pub-footer-element {
  width: 100%;
  height: auto;
}