.flex-header {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  height: 112px;
  width: 80%;
  margin-top: 52px;
  align-self: center;
}

.mpicon {
  position: absolute;
  width: 199px;
  height: 112px;
  left: 0px;
  top: 0px;
  cursor: pointer;
}

.header-tab-font {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;

  color: #ffffff;
}

.events {
  position: absolute;
  width: 82px;
  height: 26px;
  right: 240px;
  top: 10px;
  cursor: pointer;
}

/* .events {
  position: absolute;
  width: 82px;
  height: 26px;
  right: 420px;
  top: 10px;
  cursor:pointer;
} */

.join {
  position: absolute;
  width: 81px;
  height: 26px;
  right: 130px;
  top: 10px;
  cursor: pointer;
}

/* .join {
  position: absolute;
  width: 81px;
  height: 26px;
  right: 305px;
  top: 10px;
  cursor:pointer;
} */

/* .pubs {
  position: absolute;
  width: 149px;
  height: 26px;
  right: 124px;
  top: 10px;
  cursor:pointer;
} */

.about {
  position: absolute;
  width: 104px;
  height: 26px;
  right: 0px;
  top: 10px;
  cursor: pointer;
}

.donate-btn {
  display: flex;
  align-items: center;
  position: absolute;
  width: 195px;
  height: 47px;
  right: 0px;
  top: 65px;
  background: #ffffff;
  border-radius: 5px;
  border-color: transparent;
  cursor: pointer;
  padding: 0;
  border: none;
}

.donate-btn-txt {
  width: 100%;
  height: 100%;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

/* Dropdown menu for smaller screens
  has a row and column layout based on screen size */
.mobile-icon {
  display: none;
  width: 25px;
  height: 25px;
}

.dropdown-menu-background {
  position: relative;
  margin-top: 20px;
  height: 50px;
  width: 100vw;
  background-color: #151371;
  border-top: solid 2px #ffffff;
  border-bottom: solid 2px #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 0;
  visibility: hidden;
}

.join-drop {
  display: flex;
}
.join-drop a {
  display: flex;
}

@media screen and (max-width: 1100px) {
  .dropdown-menu-background {
    visibility: visible;
  }
  .donate-btn {
    display: none;
  }
  .dropdown-menu-background .donate-btn {
    display: block;
    position: initial;
  }
  .mobile-icon {
    display: block;
    position: absolute;
    top: 35px;
    right: 0px;
    cursor: pointer;
  }
  .donate-btn {
    right: 70px;
    top: 25px;
  }
  .events {
    display: none;
  }
  .join {
    display: none;
  }
  .pubs {
    display: none;
  }
  .about {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .dropdown-menu-background {
    flex-direction: column;
    height: auto;
  }
  .events-drop {
    margin: 10px;
  }
  .join-drop {
    margin: 10px;
  }
  .pubs-drop {
    margin: 10px;
  }
  .about-drop {
    margin: 10px;
  }
}
