.WAC-container {
  position: absolute;
  width:550px;
  height:400px;
  top:0px;
  left:0px;
  position: absolute;
}

.WAC-img {
  position: absolute;
  width: 550px;
  height: 258px;
  left: 0px;
  top: 0px;
}

.WAC-condensed {
  position: absolute;
  width: 550px;
  height: 143px;
  left: 0px;
  top: 258px;
  background: #FFFFFF;
}

.WAC-title {
  position: absolute;
  width: 488px;
  height: 64px;
  left: 23px;
  top: 11px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  
  color: #000000;
}

.WAC-author {
  position: absolute;
  width: 112px;
  height: 26px;
  left: 23px;
  top: 91px;
  
  font-family: Red Hat Display;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  
  color: #000000;
}

.WAC-plus {
  position: absolute;
  right: 15px;
  bottom: 12px;
  cursor:pointer;
}