.home-background {
  position: relative;
  width: 100%;
  height: auto;

  background: linear-gradient(116.97deg, #181472 0%, #ff00b8 105.35%);
  display: flex;
  flex-direction: column;
}

.home-black-background {
  position: absolute;
  width: 100%;
  height: 1705px;
  left: 0px;
  top: 735px;
  background: #000000;
}

.home-header-element {
  height: auto;
  width: 100%;
}

.home-header-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home-header {
  position: relative;
  width: 89%;
  height: auto;
  left: 11%;
  padding-top: 68px;

  font-family: Red Hat Display;
  color: #ffffff;

  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 95px;
}

.home-dt {
  position: relative;
  width: 89%;
  left: 11%;
  padding-bottom: 50px;
  height: auto;

  font-family: Red Hat Display;
  color: #ffffff;

  font-style: italic;
  font-weight: 500;
  font-size: 50px;
  line-height: 66px;
}

.home-news-flex-container {
  padding-bottom: 50px;
  background: #000000;
  min-height: 560px;
  height: auto;
  width: 100%;
}

.home-style-line {
  position: relative;
  width: 244px;
  height: 0px;
  left: 0px;
  top: 0px;

  border: 5px solid #ffffff;
  background-color: white;
}

.home-subheader {
  position: relative;
  height: 53px;
  padding-left: 145px;
  top: 0px;

  font-family: Red Hat Display;
  color: #ffffff;

  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  /* identical to box height */
}

.home-news-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: auto;
  width: 100%;
  top: 22px;
}

.home-news-arrow-container {
  width: 20%;
  margin: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-right-arrow,
.program-right-arrow {
  position: relative;
  width: 66px;
  height: 66px;
  top: 25%;
  cursor: pointer;
}

.home-left-arrow,
.program-left-arrow {
  position: relative;
  width: 66px;
  height: 66px;
  top: 45%;
  transform: scaleX(-1);
  cursor: pointer;
}

.home-news-swiper-container {
  position: relative;
  width: 80%;
  margin: 0;
  height: 455px;
  right: 0px;
  top: 10px;
  padding-bottom: 20px;
  overflow: hidden;
}

.swiper-container {
  height: 100%;
}
.program-swiper-container {
  display: flex;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 750px) {
  .home-news-arrow-container {
    width: 15%;
  }
  .home-news-swiper-container {
    width: 80%;
    margin-left: auto;
  }
}

.home-mission-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.mission {
  position: relative;
  width: calc(90% - 247px);
  left: 247px;
  margin-top: 40px;
  margin-bottom: 20px;

  font-family: Red Hat Display;
  color: #ffffff;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

@media screen and (max-width: 750px) {
  .mission {
    width: 70%;
    left: 20%;
  }
}

.home-pgm-flex-container {
  margin-top: 100px;
  padding-top: 50px;
  background: #000000;
  width: 100%;
  height: 700px;
}

.pgm-txt-flex-container {
  position: relative;
  width: 80%;
  left: 20%;
  top: 30px;
  height: 32px;
  display: flex;
  flex-direction: row;
  gap: 4%;
}

.pgm-completed {
  position: relative;
  width: 129px;
  height: 32px;
  /* left: 0px; */
  top: 0px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  color: #ff00b8;
  cursor: pointer;
}

.pgm-in-progress {
  position: relative;
  width: 123px;
  height: 32px;
  /* left: 17%; */
  top: 0px;
  padding-left: 7px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  color: #ffffff;
  cursor: pointer;
}

.pgm-line {
  position: relative;
  width: calc(80% - 6px);
  height: 0px;
  left: 20%;
  top: 50px;

  border: 3px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
}

.pgm-complete-line {
  position: relative;
  width: 129px;
  height: 0px;
  /* left: 20%; */
  top: 14px;

  border: 3px solid #ff00b8;
  background-color: #ff00b8;
}

.pgm-progress-line {
  position: relative;
  width: 123px;
  height: 0px;
  /* left: 33.25%; */
  top: 14px;

  border: 3px solid #ffffff;
  background-color: #ffffff;
}

.home-pgm-container {
  position: relative;
  width: 80%;
  height: 455px;
  top: 40px;
  overflow: hidden;
}

.focus-flex-container {
  height: auto;
  width: 100%;
}

.focus-container {
  position: relative;
  width: 100%;
  top: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3%;
}

.focus-box {
  width: 363px;
  height: 465px;
  display: flex;
  flex-direction: column;
}

.focus-img {
  position: relative;
  width: 363px;
  height: 356px;
  left: 0px;
  top: 0px;
}

.title-backgrounds {
  position: relative;
  width: 363px;
  height: 100px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-txt {
  width: 193px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  color: #000000;
}

.comp-pos {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  z-index: 1;
}

.home-globe {
  position: absolute;
  bottom: 10px;
}

.no-programs {
  top: 0px;
  font-family: Red Hat Display;
  color: #f5f5f5;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
@media screen and (max-width: 950px) {
  .home-globe {
    bottom: 340px;
  }
}
@media screen and (max-width: 550px) {
  .home-globe {
    width: 100%;
  }
}
@media screen and (max-width: 508px) {
  .home-globe {
    bottom: 740px;
  }
  .logo-link {
    width: 40%;
  }
  .logo-link img {
    width: 100%;
  }
  .footer-right-links {
    width: 40%;
  }
  .footer-right-links img {
    width: 100%;
  }
}
.home-email-flex-container {
  width: 100%;
  height: 660px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.home-join-header {
  position: relative;
  width: 44%;
  left: 45%;
  top: 150px;

  font-family: Red Hat Display;
  color: #ffffff;

  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
}

.home-join-subheader {
  position: relative;
  width: 45%;
  left: 44%;
  top: 150px;

  font-family: Red Hat Display;
  color: #ffffff;

  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
}

.email-field {
  background: #ffffff;
  border-radius: 10px;
  border-width: 0px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding-left: 30px;
}

.home-email-field {
  position: relative;
  width: 52.5%;
  height: 66px;
  top: 200px;
  left: 38%;
}

/* https://stackoverflow.com/questions/14524328/changing-font-family-for-placeholder/23491801 */
.email-field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #9a9a9a;
  opacity: 1; /* Firefox */
}

.email-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #9a9a9a;
}

.email-field::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #9a9a9a;
}

.email-btn-container {
  position: relative;
  width: 54.5%;
  height: 66px;
  top: 200px;
  left: 38%;
}

.email-field-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 64px;

  position: absolute;
  width: 195px;
  height: 47px;
  top: -57px;
  right: 1%;

  background: linear-gradient(94.37deg, #ff00b8 -20.73%, #151371 127.37%);
  border-radius: 5px;
  border-width: 0px;
  cursor: pointer;
}

.email-field-btn-txt {
  position: static;
  width: 68px;
  height: 26px;
  left: 63.5px;
  top: 10.5px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.mailchimp-status {
  position: relative;
  top: 150px;
  left: calc(50vw - 60px);
  width: 120px;
  background-color: pink;
  font-family: Red Hat Display;
  font-size: 30px;
  color: #ffffff;
  text-align: center;
}

.card-img-container {
  width: 100%;
  position: relative;
  margin: 0 auto;
}
@media screen and (max-width: 1158px) {
  .focus-box:not(:nth-last-of-type(1)) {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1158px) {
  .focus-box:not(:nth-last-of-type(1)) {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 800px) {
  .home-email-field {
    left: auto;
    align-self: center;
    width: 70%;
  }
  .email-btn-container {
    left: auto;
    align-self: center;
    width: 70%;
    top: 270px;
  }
  .email-field-btn {
    right: 0%;
    /* align-self: center; */
  }
  .home-spacer {
    position: relative;
    height: 200px;
    width: 100%;
  }
  .home-join-header {
    position: initial;
    width: 100%;
    text-align: center;
  }
  .home-email-flex-container {
    justify-content: center;
  }
  .home-join-subheader {
    width: 90%;
    margin: 0 auto;
    position: initial;
    text-align: center;
  }
  .news-email-form {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .news-email-form input {
    position: initial;
  }
  .email-btn-container {
    top: 0;
  }
}

.home-footer-flex-container {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 650px) {
  .news-email-form {
    position: relative;
  }
  .email-btn-container,
  .email-btn-container button {
    position: initial;
  }
  .email-btn-container button {
    margin-left: auto;
    margin-top: 12px;
  }
}
@media screen and (max-width: 550px) {
  .program-swiper-container {
    flex-direction: column-reverse;
  }
  .home-news-arrow-container {
    width: 90%;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    margin-top: 60px;
    margin-left: auto;
  }
  .home-news-container {
    flex-direction: column-reverse;
  }
  .home-pgm-container {
    margin-left: auto;
    width: 90%;
  }
  .home-news-container .home-news-arrow-container {
    margin-top: 10px;
  }
  .home-news-flex-container {
    padding-top: 40px;
  }
  .pgm-txt-flex-container {
    width: 90%;
    left: 10%;
  }
  /* .pgm-line {
    width: calc(90% - 6px);
  } */
  .home-news-swiper-container {
    width: 90%;
  }
}
@media screen and (max-width: 498px) {
  .home-email-flex-container {
    height: 760px;
  }
}

@media screen and (max-width: 400px) {
  .focus-box {
    width: 100%;
  }
  .card-img-container {
    width: 90%;
  }
  .focus-img {
    width: 100%;
  }
  /* .comp-pos {
    margin: 0 auto;
    left: 0;
    width: 50%;
  } */
  .title-backgrounds {
    width: 90%;
    margin: 0 auto;
  }
}
