.PE-container {
  position: absolute;
  height:370px;
  width:283px;
  top:0px;
  left:0px;
}

.PE-img {
  position: absolute;
  width: 283px;
  height: 197px;
  left: 0px;
  top: 0px;
}

.PE-info {
  position: absolute;
  width: 283px;
  height: 173px;
  left: 0px;
  top: 197px;

  background: #FFFFFF;
}

.PE-title {
  position: absolute;
  width: 100%;
  height: 32px;
  left: 20px;
  top: 10px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  
  
  color: #000000;
}

.PE-txt {
  position: absolute;
  width: 242px;
  height: 96px;
  left: 20px;
  top: 45px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  
  color: #000000;
}

.PE-plus {
  position: absolute;
  right: 14px;
  bottom: 12px;
  cursor: pointer;
}