.program-container {
  width: 364px;
  height: 455px;
  background: white;
  display: flex;
  flex-direction: column;
}

.pgm-img {
  object-fit: cover;
  width: 364px;
  height: 252px;
}

.pgm-info-condensed {
  width: 364px;
  height: 61px;
}

.pgm-info-expanded {
  width: 364px;
  height: 191px;
}

.pgm-info-title {
  width: fit-content;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 6px 25px;
}

.pgm-info-content {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  padding: 0px 25px;
}

.pgm-expand,
.pgm-condense {
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
}
.pgm-expand svg,
.pgm-condense svg {
  margin-right: 4px;
}
.pgm-expand svg path,
.pgm-condense svg path {
  stroke: #000000;
}
