.AC-container {
  position: absolute;
  width:311px;
  height:478px;
  top:0px;
  left:0px;
}

.AC-img {
  position: absolute;
  width: 311px;
  height: 258px;
  left: 0px;
  top: 0px;
}

.AC-condensed {
  position: absolute;
  width: 311px;
  height: 101px;
  left: 0px;
  top: 258px;
  background: #FFFFFF;
}

.AC-expanded {
  position: absolute;
  width: 311px;
  height: 220px;
  left: 0px;
  top: 258px;
  background: #FFFFFF;
}

.AC-title {
  position: absolute;
  width: 52px;
  height: 32px;
  left: 20px;
  top: 5px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */
  
  
  color: #000000;
}

.AC-author {
  position: absolute;
  width: 90px;
  height: 26px;
  left: 20px;
  top: 43px;
  
  font-family: Red Hat Display;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  
  color: #000000;
}

.AC-txt {
  position: absolute;
  width: 272px;
  height: 118px;
  left: 20px;
  top: 75px;
  
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  
  color: #000000;
}

.AC-plus {
  position: absolute;
  right: 15px;
  bottom: 12px;
  cursor:pointer;
}

.AC-minus {
  position: absolute;
  right: 15px;
  bottom: 21px;
  cursor:pointer;
}

.coming-soon-blur {
  position: absolute;
  left: 0px;
  top:88px;
  height:82px;
  width:100%;

  background: rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.coming-soon-txt {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  /* identical to box height */


  color: #FFFFFF;

  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
}