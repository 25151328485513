.footer {
  position: relative;
  /* width: 100%; */
  background: #000000;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.footer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: auto;
}

.logo-container {
  width: 197px;
  height: 202px;
  left: 0px;
  top: 0px;
}

.footer-heading-font {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  color: #ffffff;
  white-space: nowrap;
}

.footer-subheading-font {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
  white-space: nowrap;
}

.footer-txt-margin {
  margin-top: 25px;
}

.footer-links-container {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.company-container {
  display: flex;
  flex-direction: column;
  width: 110px;
  height: 202px;
}

.donate-container {
  display: flex;
  flex-direction: column;
  width: 122px;
  height: 199px;
}

.follow-container {
  display: flex;
  flex-direction: column;
  width: 126px;
  height: 202.23px;
}

.follow-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.footer-mplogo {
  cursor: pointer;
}
.footer-right-links {
  display: flex;
  flex-direction: column;
}
.footer-copyright {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #9a9a9a;
  margin-top: 2rem;
}
.footer-arrow {
  width: 115px;
  height: 100px;
  left: calc((100% - 57.5px) / 2);
  cursor: pointer;
  margin: auto;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 950px) {
  .footer-container {
    gap: 50px;
    flex-wrap: wrap;
  }
  .footer-links-container {
    order: 2;
    flex-direction: row;
    width: 100%;
  }
  .company-container {
    flex-wrap: wrap;
    padding-bottom: 20px;
    height: auto;
    gap: 10px;
  }
  .donate-container {
    flex-direction: column;
    padding-bottom: 20px;
    height: auto;
    gap: 10px;
  }
  .follow-container {
    flex-direction: column;
    padding-bottom: 20px;
    height: auto;
    gap: 10px;
  }
  .footer-mplogo {
    right: 5%;
  }
  .footer-mvclogo {
    right: 5%;
  }
  .footer-arrow {
    bottom: 75px;
  }
}
@media screen and (max-width: 508px) {
  .footer-container {
    justify-content: center;
  }
}
@media screen and (max-width: 508px) {
  .footer-links-container {
    flex-direction: column;
  }
  .footer-txt-margin {
    margin-top: 10px;
  }
  .footer-links-container > div:not(:last-child) {
    margin-bottom: 20px;
  }
}
