/* To fix a weird whitespace bug */
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
body {
  overflow-x: hidden;
}

.about-background {
  position: absolute;
  width: 100%;
  height: auto;

  background: linear-gradient(116.97deg, #181472 0%, #ff00b8 105.35%);
  display: flex;
  flex-direction: column;
}

.about-black-background1 {
  position: absolute;
  width: 100%;
  height: 794px;
  left: 0px;
  top: 875px;

  background: #000000;
}

.about-black-background2 {
  position: absolute;
  width: 100%;
  height: 1121px;
  left: 0px;
  top: 2709px;

  background: #000000;
}

.about-header-element {
  height: auto;
  width: 100%;
}

.about-header-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.about-page-title {
  position: relative;
  width: 25%;
  height: auto;
  left: 11%;
  margin-top: 68px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 900;
  font-size: 72px;
  line-height: 95px;

  color: #ffffff;
}

.about-page-subtitle {
  position: relative;
  width: 68%;
  height: auto;
  left: 11%;
  top: 0px;
  margin-bottom: 67px;

  font-family: Red Hat Display;
  font-style: italic;
  font-weight: 500;
  font-size: 50px;
  line-height: 66px;

  color: #ffffff;
}

.about-why-flex-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.about-subheader {
  position: relative;
  width: 106px;
  height: 53px;
  left: 141px;
  top: 0px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  /* identical to box height */
  text-align: right;

  color: #ffffff;
}

.about-style-line {
  position: relative;
  width: 247px;
  height: 0px;
  left: 0px;
  top: 0px;

  border: 5px solid #ffffff;
  background-color: #ffffff;
}

.about-goal-txt {
  position: relative;
  width: calc(90% - 247px);
  left: 247px;
  height: auto;
  margin-top: 16px;
  margin-bottom: 76px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #ffffff;
}

.about-mission-flex-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.about-mission-txt {
  position: relative;
  width: calc(90% - 247px);
  left: 247px;
  height: auto;
  margin-top: 15px;
  margin-bottom: 95px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #ffffff;
}

.about-board-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background: #000000;
  padding-top: 50px;
}

.about-board-style-line {
  position: relative;
  width: calc(80% - 6px);
  height: 0px;
  left: 20%;
  top: 90px;

  border: 3px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
}

.about-board-component-container {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 17px;
}

.about-board-container {
  position: relative;
  width: 80%;
  height: 455px;
  left: 20%;
  margin-top: 100px;
  margin-bottom: 53px;
  overflow: hidden;
}

.about-fellowship-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #000000;
}

.about-fellowship-txt {
  position: relative;
  width: calc(90% - 247px);
  left: 247px;
  height: auto;
  margin-top: 19px;
  margin-bottom: 148px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #ffffff;

  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

@media screen and (max-width: 750px) {
  .about-fellowship-txt,
  .about-mission-txt,
  .about-goal-txt {
    width: 70%;
    left: 20%;
  }
}

.about-professors-flex-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.prof-arrow-container {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prof-container {
  position: relative;
  width: 1068px;
  height: 719px;
  margin-top: 19px;
  margin-bottom: 66px;
  align-self: center;
  overflow: hidden;
}

.prof-card-container {
  position: relative;
  width: 319px;
  height: 383px;
  margin-top: 56px;
}

.prof-img {
  position: relative;
  width: 319px;
  height: 319px;
  top: 0px;
}

.prof-name-txt {
  position: relative;
  top: 0px;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: right;

  color: #ffffff;
}

.prof-small-txt {
  position: relative;
  margin-top: 11px;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: #ffffff;
  visibility: hidden;
}

.swiper-slide-next {
  width: 430px;
}

.swiper-slide-next .prof-card-container {
  width: 430px;
  height: 719px;
  margin-top: 0px;
}

.swiper-slide-next .prof-card-container .prof-small-txt {
  visibility: visible;
}

.swiper-slide-active .prof-card-container .prof-name-txt {
  text-align: left;
}

/* margin attempt to fix prof swiper */
.prof-container .swiper-slide-active .prof-card-container {
  margin-left: 150px;
}

.swiper-slide-next .prof-card-container .prof-name-txt {
  position: relative;
  width: 298px;
  height: 96px;
  text-align: left;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;

  color: #ffffff;
}

.swiper-slide-next .prof-card-container .prof-img {
  width: 430px;
  height: 430px;
}

.about-left-arrow {
  position: relative;
  width: 66px;
  height: 66px;
  left: 2%;
  top: 200px;
  transform: scaleX(-1);
  cursor: pointer;
}

.about-right-arrow {
  position: relative;
  width: 66px;
  height: 66px;
  right: 2%;
  top: 200px;
  cursor: pointer;
}

.prof-container .swiper-slide {
  width: fit-content !important;
}

.about-what-we-do-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.about-circle-flex-container {
  width: 100%;
  position: relative;
  margin-top: 15px;
  margin-bottom: 35px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 7%;
}

.about-section-container {
  position: relative;
  top: 0px;
  width: 250px;
  height: 407px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-container {
  position: relative;
  width: 161px;
  height: 140px;
}

.circle {
  position: absolute;
  left: 21px;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  background-color: #ff00b8;
}

.circle-border {
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  border: 3px solid #ff00b8;
  box-sizing: border-box;
}

.circle-icon {
  position: absolute;
  left: 45px;
  top: 40px;
}

.what-we-do-header {
  position: absolute;
  width: 152px;
  height: 48px;
  top: 176px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.what-we-do-txt {
  position: absolute;
  width: 250px;
  height: 173px;
  top: 234px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
}

.about-globe {
  position: absolute;
  bottom: 10px;
}

@media screen and (max-width: 950px) {
  .about-globe {
    bottom: 340px;
  }
}
@media screen and (max-width: 550px) {
  .about-globe {
    width: 100%;
  }
}
@media screen and (max-width: 508px) {
  .about-globe {
    bottom: 740px;
  }
}
.about-email-flex-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.about-join-header {
  position: relative;
  width: 54%;
  height: auto;
  left: 40%;
  margin-top: 193px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  /* identical to box height */

  color: #ffffff;
}

.about-join-subheader {
  position: relative;
  width: 35%;
  height: auto;
  left: 45%;
  margin-top: 5px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height */

  color: #ffffff;
}

.about-email-field {
  position: relative;
  width: 52.5%;
  height: 66px;
  top: 0px;
  left: 35%;
  margin-top: 50px;
}

.about-btn-container {
  position: relative;
  width: 54.5%;
  height: 66px;
  top: 0px;
  left: 35%;
  margin-bottom: 100px;
}

@media screen and (max-width: 800px) {
  .about-email-field {
    left: auto;
    align-self: center;
    width: 70%;
  }
  .about-btn-container {
    left: auto;
    align-self: center;
    width: 70%;
    top: 70px;
  }
  .email-field-btn {
    right: 0%;
    /* align-self: center; */
  }
  .about-spacer {
    position: relative;
    height: 200px;
    width: 100%;
  }
}

.about-footer-element {
  width: 100%;
  height: auto;
}

/* for the board components */

.board-component-container {
  position: absolute;
  /* width: 48px; */
  height: 67px;
  left: 0px;
  top: 0px;
}

.board-component-txt-yellow {
  position: absolute;
  height: 32px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  text-transform: uppercase;

  color: #fdbf07;
  cursor: pointer;
}

.board-component-line-yellow {
  position: absolute;
  height: 0px;
  top: 67px;

  border: 3px solid #fdbf07;
  background-color: #fdbf07;
}

.board-component-txt {
  position: absolute;
  height: 32px;

  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
  cursor: pointer;
}

.board-component-line {
  position: absolute;
  height: 0px;
  top: 67px;

  border: 3px solid #ffffff;
  background-color: #ffffff;
}
@media screen and (max-width: 800px) {
  .home-email-field {
    left: auto;
    align-self: center;
    width: 70%;
  }
  .email-btn-container {
    left: auto;
    align-self: center;
    width: 70%;
    top: 270px;
  }
  .email-field-btn {
    right: 0%;
    /* align-self: center; */
  }
  .home-spacer {
    position: relative;
    height: 200px;
    width: 100%;
  }
  .home-join-header {
    position: initial;
    width: 100%;
    text-align: center;
  }
  .home-email-flex-container {
    justify-content: center;
  }
  .home-join-subheader {
    width: 90%;
    margin: 0 auto;
    position: initial;
    text-align: center;
  }
  .news-email-form {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .news-email-form input {
    position: initial;
  }
  .email-btn-container {
    top: 0;
  }
}

.home-footer-flex-container {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 800px) {
  .about-board-component-container > div:nth-child(2) {
    left: 35% !important;
  }
  .about-board-component-container > div:nth-child(3) {
    left: 60% !important;
  }
}
